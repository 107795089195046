// --- Vendors ---


// --- Components ---
const homePage = {
    readyFn: function () {
        console.log("ioja");




    },
}
export { homePage };