// --- Vendors ---
import Swiper, { Navigation, Pagination, Thumbs, Mousewheel } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Mousewheel]);
// --- Components ---
const swiper = {
    readyFn: function () {

        console.log("swiper ready");

        let swiperArray = [];
        $('.swiper-container').each(function (index) {
            let swiperId = $(this).attr('id');
            let prevBtn = document.getElementById(swiperId).querySelector('.swiper-prev');
            let nextBtn = document.getElementById(swiperId).querySelector('.swiper-next');

            let params = {
                spaceBetween: 0,
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    prevEl: prevBtn,
                    nextEl: nextBtn
                }
            }

            let slider = new Swiper('#' + swiperId, params);
        });

        /* SWIPER GALERIE */
        let swiperGalerieArray = [];
        $('.swiper-container-galerie').each(function (index) {
            let swiperId = $(this).attr('id');
            let prevBtn = document.getElementById(swiperId).querySelector('.swiper-prev');
            let nextBtn = document.getElementById(swiperId).querySelector('.swiper-next');

            let params = {
                spaceBetween: 0,
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    prevEl: prevBtn,
                    nextEl: nextBtn
                }
            }

            let sliderGalerie = new Swiper('#' + swiperId, params);
        });

        /* SWIPER ACTUALITED */
        let swiperActuArray = [];
        $('.swiper-container-actu').each(function (index) {
            let swiperId = $(this).attr('id');
            let prevBtn = document.getElementById(swiperId).querySelector('.swiper-prev');
            let nextBtn = document.getElementById(swiperId).querySelector('.swiper-next');
            let params = {
                slidesPerView: 1,
                spaceBetween: 10,
                breakpoints: {
                    // when window width is >= 1200px
                    1200: {
                        slidesPerView: 2.5,
                        spaceBetween: 30
                    },
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    prevEl: prevBtn,
                    nextEl: nextBtn
                }
            }

            let sliderActu = new Swiper('#' + swiperId, params);
        });
    },
}
export { swiper };